import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Client } from "api-client";

import AppConfig from "~/configs/next";

const API_SERVER_PROXY = `/api/treasured`;

let client;

function createClient(isServer) {
  return new Client({
    basePath: isServer ? AppConfig.API_HOST : API_SERVER_PROXY,
  });
}

export function initializeTreasuredClient() {
  const isServer = typeof window === "undefined";
  const _client = client ?? createClient(isServer);

  // For SSG and SSR always create a new client
  if (isServer) return _client;

  // Create the Client once in the browser
  if (!client) client = _client;

  return _client;
}

export const TreasuredClientContext = React.createContext(null);

export function useTreasuredClient() {
  const client = useContext(TreasuredClientContext);
  return client;
}

export function TreasuredClientProvider({ children }) {
  const client = useMemo(() => initializeTreasuredClient(), []);
  return (
    <TreasuredClientContext.Provider value={client}>
      {children}
    </TreasuredClientContext.Provider>
  );
}

TreasuredClientProvider.propTypes = {
  children: PropTypes.node,
};
