import { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { useQuery } from "react-query";

import AppConfig from "~/configs/next";
import { useTreasuredClient } from "~/contexts/treasured-client";

export default function useAuth() {
  const client = useTreasuredClient();
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak?.authenticated;

  const { data: currentUser, isFetching: isLoading } = useQuery(
    ["users", "me"],
    () => client.auth.getCurrentUser(),
    {
      enabled: initialized && isLoggedIn,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const logout = useCallback(
    () => keycloak.logout({ redirectUri: `https://${AppConfig.HOST}` }),
    [keycloak]
  );

  const login = useCallback(() => keycloak.login(), [keycloak]);

  return { login, logout, currentUser, isLoggedIn, isLoading };
}
